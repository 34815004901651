
import React from 'react';
import Chart from "react-apexcharts";

const BarChart = ({ impactData }) => {
  const chartOptions = {
    chart: {
      type: "bar",
      background: "#FEFEFE",
      animations: { enabled: false },
      fontFamily: "Roboto",
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    
    plotOptions: {
      bar: {
        columnWidth: "70%",
        borderRadius: 20,
        borderRadiusApplication: "end",
      },
    },
    colors: ["#216778", "#B9ECE9"],
    grid: {
      padding: { top: 40, right: 0, bottom: 0, left: 0 }, // Increased top padding for title
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      fontFamily: "Montserrat",
      fontSize: '15px', 
      markers: { size: 6 },
      itemMargin: { horizontal: 10, vertical: 5 },
      labels: { 
        useSeriesColors: false,
        colors: "#878787",
        fontFamily: "Montserrat" 
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: impactData?.map(data => data?.date),
      labels: {
        style: { fontSize: "12px", fontWeight: 500, fontFamily: "Roboto", colors: "#919191" },
      },
    },
    yaxis: {
      title: {
        text: "# Incidents",
        style: { 
          fontSize: "16px", 
          fontWeight: 500, 
          fontFamily: "Montserrat", 
          color: "#878787" 
        },
        offsetX: -10,
      },
      labels: {
        style: { fontSize: "12px", fontWeight: 400, fontFamily: "Roboto", colors: "#919191" },
      },
      tickAmount: 4,
    },
    tooltip: {
      shared: true,
      intersect: false,
      style: { fontFamily: "Roboto" },
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none'
        }
      }
    },
  };

  const chartSeries = [
    {
      name: "With Ocean Bot",
      data: impactData.map(data => data.with_ocean_ac),
    },
    {
      name: "Without Ocean Bot",
      data: impactData.map(data => data.without_ocean_ac),
    },
  ];

  return (
    <div style={{ width: "100%", height: "330px" }}>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height="100%"
      />
    </div>
  );
};

export default BarChart;