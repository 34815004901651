import { useEffect, useState } from "react";

export function Calendar(data: any) {
 

  return (
    <>
      {" "}
      <input
                            type="date"
                            className="form-date custom-select custom-form-input font-regular font-14"
                            name="fromDate"
                            id="fromDate"
                            
                            onChange={(e: any) => {
                                data.record.func(e.target.value, "drpdwn");
                            }}
                            // min={filterData.fromDate}
                          />
      
    </>
  );
}