
import React from 'react';
import Chart from "react-apexcharts";

const DonutChart = ({ solved, unresolved }) => {
  const total = solved + unresolved;

  const options = {
    chart: {
      type: "donut",
    },
    labels: ["Resolved Incidents", "Unresolved Incidents"],
    colors: ["#51CFC9", "#216778"],
    legend: {
      position: "right",
      offsetY: 50,
      fontSize: '12px',
      fontFamily: 'Montserrat, sans-serif', 
      markers: {
        offsetX: -5,
      },
      itemMargin: {
        vertical: 10
      },
      formatter: function(seriesName, opts) {
        return [
          `<div style="display: flex; align-items: center; font-size: 12px;">`,
          `<span style="font-weight: 500;">${seriesName}</span>`,
          `<span style="margin-left: 15px;"><strong>${opts.w.globals.series[opts.seriesIndex]}</strong></span>`,
          `</div>`
        ].join('')
      }
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} Incidents`,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '75%',
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: 'Total Incidents',
              fontSize: '16px',
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 400,
              color: '#878787',
              formatter: function (w) {
                return total
              }
            },
            value: {
              fontSize: '28px',
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 600,
              color: '#000000',
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: false
    }
  };

  const series = [solved, unresolved];

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Chart 
        options={options} 
        series={series} 
        type="donut" 
        width="460px"
      />
    </div>
  );
};

export default DonutChart;