import axios from "axios";
import { AxiosResponse } from "axios";
import { AxiosRequestConfig } from 'axios';
export async function client(endpoint: string, body: any, requestType: string) {
  
  try {
    let url = `${endpoint}`;

  
    let config = {
      method: requestType,
      url: endpoint,
      data: body,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };


    const results = await axios(config);

    

    return results;

  } catch (error:any) {
    
    return error
  
  }
}

export const apiCall = async(method:string,url:string,data?:any)=>{
   
    
  const config={
      method,
      url,
      data,
  }
  
  const apiResponse :AxiosResponse = await axios(config)
  return apiResponse
}


export const sendRequest = (method: string, url: string, data?: any) => {
  const config: AxiosRequestConfig = {
    method,
    url,
    data,
  };
  

  // Send the HTTP request and discard the promise
  axios(config).then().catch((error) => {
    // Handle the error if needed
    
  });
};