import { Text } from "./HTMLComponents/Text";
import { DropDown } from "./HTMLComponents/Dropdown";
import { Choices } from "./HTMLComponents/Choices";
import { MultiChoices } from "./HTMLComponents/MultiChoices";
import { Buttons } from "./HTMLComponents/Buttons";
import { Image } from "./HTMLComponents/Image";
import { Document } from "./HTMLComponents/Document";
import { Link } from "./HTMLComponents/Link";
import { Table } from "./HTMLComponents/Table";
import { Calendar } from "./HTMLComponents/Calender";
/**
 * PC_CB_2.1 to PC_CB_2.3 necessary imports 
 * PC_CB_2.4 to PC_CB_2.5 call other component using swith case by type
 * PC_CB_2.6 to PC_CB_2.9 return the txt component as type txt
 * PC_CB_2.10 to PC_CB_2.15 return the choices component as type chcs
 * PC_CB_2.16 to PC_CB_2.21 retrun multichoices component as type multichcs
 * PC_CB_2.22 to PC_CB_2.25 retrun table component as tbl
 * PC_CB_2.26 to PC_CB_2.30 retrun link component as type lnk
 * PC_CB_2.31 to PC_CB_2.35 documents component htmls
 * PC_CB_2.36 to PC_CB_2.39 dropdown component html
 * PC_CB_2.40 to PC_CB_2.44 button component
 * PC_CB_2.45 to PC_CB_2.48 image component
 * @param data 
 * @returns 
 */
export function BindBotResponse(data: any) {
  const { contentType, content } = data?.record.value;
  
  const returnComponents = () => {
    
    switch (contentType) {
      case "txt":
        return (
          <>
            <div>
              <Text record={{ value: content ,role:data?.record?.role}} />
            </div>
          </>
        );
      case "drpdwn":
        return (
          <>
            <div className="col-md-6 mb-4 mt-3">
              <DropDown
                record={{
                  value: content,
                  func: data.record.func,
                }}
              />
            </div>
          </>
        );
      case "chcs":
        return (
          <>
            <div className="col-md-6 mb-4 mt-3">
              <Choices
                record={{
                  value: content,
                  func: data.record.func,
                }}
              />
            </div>
          </>
        );
      case "multchc":
        return (
          <>
            <div className="col-md-6 mb-4 mt-3">
              <MultiChoices
                record={{
                  value: content,
                  func: data.record.func,
                }}
              />
            </div>
          </>
        );
      case "btn":
        return (
          <>
            <div className="btn-container">
                <Buttons
                  record={{
                    value: content,
                    func: data.record.func,
                    msg:data?.record?.message
                  }}
                />
              </div>
          </>
        );
      case "lnk":
        return (
          <>
            <div className="col-md-6 mb-4 mt-3">
              <Link record={{ value: content }} />
            </div>
          </>
        );
      case "img":
        return (
          <>
            <div className="col-md-6 mb-4 mt-3">
              <Image record={{ value: content }} />
            </div>
          </>
        );
      case "doc":
        return (
          <>
            <div className="col-md-6 mb-4 mt-3">
              <Document record={{ value: content }} />
            </div>
          </>
        );
      case "tbl":
        return (
          <>
            <div className="col-md-6 mb-4 mt-3">
              <Table record={{ value: content }} />
            </div>
          </>
        );
        case "crd":
          return (
            <>
              <div className="col-md-6 mb-4 mt-3" style={{'padding':'0px'}}>
                <Table record={{ value: content }} />
              </div>
            </>
          );
          case "date":
        return (
          <>
            <div className="col-md-6 mb-4 mt-3">
              <Calendar record={{ value: content,func: data.record.func }} />
            </div>
          </>
        );
      default:
        return null; // Default case, render nothing or handle accordingly
    }
  };

  return <>{returnComponents()}</>;
}

export default BindBotResponse;
