import React, { useEffect, useState } from "react";

const linkStyle = {
  color: 'inherit',
  textDecoration: 'underline',
  cursor: 'pointer'
};

export function Text(data: any) {
  const [pointsArr, setPointsArr] = useState<string[]>([]);

  useEffect(() => {
    if (data?.record?.value) {
      Content(data.record.value);
    }
  }, [data]);

  const Content = (content: string) => {
    let points: string[];
    if (/:|\n|\d+\./.test(content)) {
      points = content.split(/:|\n/).filter((item) => item.trim() !== "");
    } else if (
      /[\u2022\u2023\u25E6\u2043\u2219\u25AA\u25AB\u25FC\u25FD\u25FE\u25AA\u00B7\u00D8\u00B0\u2219]/.test(
        content
      )
    ) {
      points = content
        .split(
          /[\u2022\u2023\u25E6\u2043\u2219\u25AA\u25AB\u25FC\u25FD\u25FE\u25AA\u00B7\u00D8\u00B0\u2219]/
        )
        .filter((item) => item.trim() !== "");
    } else {
      points = [content.trim()];
    }
    setPointsArr(points);
  };

  const renderText = (text: string) => {
    const urlRegex = /<?(https?:\/\/[^\s>]+)>?/g;
    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
      const match = part.match(urlRegex);
      if (match) {
        let url = match[0].replace(/^<|>$/g, ''); // Remove < > if present
        // Remove trailing punctuation (e.g., ., ,, !, ?) from the URL if any
        url = url.replace(/[.,!?]+$/, '');
        return (
          <a 
            className="ai-bot-content-link"
            key={index} 
            href={url} 
            target="_blank" 
            rel="noopener noreferrer"
            style={linkStyle}
          >
            {url}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <>
      {data?.record?.role === "bot" ? (
        <span>
          {pointsArr?.map((point, index) => (
            <p className="points" key={index}>
              {renderText(point.trim())}
            </p>
          ))}
        </span>
      ) : (
        renderText(data?.record?.value || "")
      )}
    </>
  );
}