import {apiCall, client, sendRequest} from "./Client";
import { Urls } from "./Api-Constant";
import { DashboardParams } from "../Interface/Interface";
/**
 * PC_CB_1.8 to PC_CB_1.9 call client function with required params to get the response
 * PC_CB_1.14 to PC_CB_1.15 return the response
 * @param body
 * @returns access token with bot config
 */
export async function getJwtToken(body: object) {
  let result = await client(Urls.generateJWT, body, "POST");
  return result;
}
/**
 * PC_CB_1.17 to PC_CB_1.18 to get the greeting message
 * PC_CB_1.25 return the response
 * @param body
 * @returns the greeting message for the user
 */
export async function getGreeting(body: object) {

  let result = await client(
    Urls.startConversation,
    body,
    "POST"
  );
  return result;
}
/**
 * PC_CB_1.55 to PC_CB_1.62 call client to get the response from API and return the response
 * @param body
 * @returns the bot response
 */
export async function makeAgentRequest(body: object) {
  let result = await client(
    Urls.processConversation,
    body,
    "POST"
  );
  return result;
}
//PS_09-20 call client to get the response from API and return the response
export const getDashboardResponse = async (params: DashboardParams) => {
  let requestBody: { time_period?: string; start_date?: string; end_date?: string };

  if (params.duration && params.duration !== 'custom') {
    requestBody = { time_period: params.duration };
  } else if (params.start_date && params.end_date) {
    requestBody = { start_date: params.start_date, end_date: params.end_date };
  } else {
    throw new Error('Invalid parameters');
  }

  const serviceResponse = await apiCall('POST', Urls.dashboard, requestBody);
  return serviceResponse;
};
//PS_33-44 call client to get the response from API and return the response
export const getDurationResponse = async () => {
    const serviceResponse = await apiCall('GET', Urls.duration);
    return serviceResponse
};
// export const sendSatisfactionRequest = async (conversation_id:string,data:any) => {
//      sendRequest('POST', Urls.satisfaction,{conversation_id:conversation_id,data:data});
// };
export const sendSatisfactionRequest = async (conversation_id: string, data: any, question?: string) => {
  const requestBody: any = {
      conversation_id: conversation_id,
      data: data,
  };

  if (question) {
      requestBody.question = question;
  }

  sendRequest('POST', Urls.satisfaction, requestBody);
};