import { useState } from "react";

export function Buttons(data: any) {
  const [disabledValue, setDisabledValue] = useState(false);
  // console.log(data);
  
  // const len = data?.record?.msg?.chatConversation?.length;
  // console.log(len);
  
  const bindchoics = () => {
    return data?.record.value.map((val: any, index: any) => (
      <>
        {" "}
        
        <button
          id={val.options}
          disabled={disabledValue }
          className={
            val.options=='Yes'?"primary-btn":"secondary-btn"
          }
          onClick={(e: any) => {
            if (e.target.id == "Features of SuperDesk") {
              setDisabledValue(true);
              data.record.func("What are some of its features?", "btn");
            } else if (e.target.id == "Overview of SuperDesk") {
              setDisabledValue(true);
              data.record.func(
                "Provide an overview on SuperDesk and how it can help me",
                "btn"
              );
            } else if (e.target.id == "ROI") {
              setDisabledValue(true);
              data.record.func(
                "Help me calculate the return on investment I am getting when I use SuperDesk",
                "btn"
              );
            } else {
              setDisabledValue(true);
              data.record.func(e.target.id, "btn");
            }
          }}
        >
          {val.options}
        </button>
      </>
    ));
  };
  return <>{bindchoics()}</>;
}
