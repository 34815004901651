import { BrowserRouter, Route, Routes } from "react-router-dom";
// import "./App.css";
import { Chatbot } from "./Components/ChatBot";
import TeamsEmbeddedBot from "./Teams/teams";
import SlackBotEmbed from "./Slack/slack";
import { Dashboard } from "./Components/Dashboard";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/oceanacdashboard" element={<Dashboard/>}></Route>
          <Route path="/" element={<Chatbot />}></Route>
          {/* <Route path="/teams" element={<TeamsEmbeddedBot />}></Route>
          <Route path="/slack" element={<SlackBotEmbed />}></Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
