import React, { useEffect, useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { getDashboardResponse, getDurationResponse } from '../Service/Api';
import BarChart from '../DashboardComponents/BarChart1';
import DonutChart from '../DashboardComponents/donutChart';
import { DashboardParams, DashBoardType, DateRange } from '../Interface/Interface';
import Loader from './Loader';
import { parseISO } from 'date-fns'
import { useNavigate } from 'react-router-dom';
import { newDate } from 'react-datepicker/dist/date_utils';

export const Dashboard = () => {
  const [dashBoardData, setdashBoardData] = useState<DashBoardType>({
    bar_graph_data: [],
    satisfactory_score: { yes_count: '', no_count: '', total_count: '', satisfaction_score: '' },
    average_response_time: '',
    average_engagement_time: { value: '', unit: '' },
    frequently_asked_question: [],
    unresolved_question: [],
    incidents: { resolved_incidents: 0, unresolved_incidents: 0, total_incidents: 0 }
  });
  const [durationData, setDurationData] = useState<String[]>([])
  const [dateRange, setDateRange] = useState<DateRange>({ startDate: null, endDate: null });
  const [showStartDatePicker, setShowStartDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState("Select Duration");
  const [loading, setLoading] = useState<Boolean>(false);
  
  const startDatePickerRef = useRef<HTMLDivElement>(null);
  const endDatePickerRef = useRef<HTMLDivElement>(null);
  const [minSelectableDate, setMinSelectableDate] = useState<Date | null>(null);
  const maxSelectableDate = new Date(); // Current date
  useEffect(() => {
    getDashboardData({ duration: "Current Month" });
    getDurationData();
    
    const handleClickOutside = (event: MouseEvent) => {
      if (
        startDatePickerRef.current &&
        !startDatePickerRef.current.contains(event.target as Node)
      ) {
        setShowStartDatePicker(false);
      }

      if (
        endDatePickerRef.current &&
        !endDatePickerRef.current.contains(event.target as Node)
      ) {
        setShowEndDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDurationChange = (duration: string) => {
    setSelectedDuration(duration);
    if (duration === "Custom Date") {
      handleCustomDateClick();
    } else {
      getDashboardData({ duration: duration });
    }
  };

  const handleCustomDateClick = () => {
    setShowStartDatePicker(true);
  };

  const handleStartDateChange = (date: Date | null) => {
    setDateRange((prevRange) => ({ ...prevRange, startDate: date }));
    setShowStartDatePicker(false);
    setShowEndDatePicker(true);
  };

  const handleEndDateChange = (date: Date | null) => {
    setDateRange((prevRange) => ({ ...prevRange, endDate: date }));
    setShowEndDatePicker(false);
    if (date && dateRange.startDate) {
      const formattedStartDate = format(dateRange.startDate, 'dd/MM/yyyy');
      const formattedEndDate = format(date, 'dd/MM/yyyy');
      getDashboardData({
        start_date: formattedStartDate,
        end_date: formattedEndDate
      });
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    if (selectedValue === 'custom') {
      handleCustomDateClick();
    } else {
      getDashboardData({ duration: selectedValue });
    }
  };

  const getDashboardData = async (params: DashboardParams) => {
    try {
      setLoading(true)
      const response = await getDashboardResponse(params);
      if (response?.status === 200) {
        setdashBoardData(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false)
    }
  };

  const getDurationData = async () => {
    try {
      const response = await getDurationResponse();
      if (response.status === 200) {
        setDurationData(response?.data?.duration);
        setMinSelectableDate(parseISO(response?.data?.earliest_date));
        
      } 

    } 
    catch (error) {
      console.error(error);
    }
  };

  const bindUnresolvedQuestion = () => {
    const questions = dashBoardData?.unresolved_question ?? [];
    
    return (
      <div className="col-md-12 col-lg-12 col-xl-6 mb-4">
        <div className="sd-card custom-height">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="font-16 font-medium color-black mb-2">
              Top Unresolved Questions
            </h3>
          </div>
          {questions.length > 0 && (
            <div className="d-flex align-items-center pt-3 pb-2">
              <div className="sd-w-80">
                <p className="color-greyv1 font-14 font-regular mb-0">
                  Questions
                </p>
              </div>
            </div>
          )}
          <ul className="sd-list list-unstyled mb-0">
            {questions.length > 0 ? (
              questions.map((question, index) => (
                <li key={index} className={`d-flex align-items-center ${index > 0 ? 'border-top' : ''}`}>
                  <div className="sd-w-80">
                    <p className="color-black font-14 font-medium mb-0">
                      {question}
                    </p>
                  </div>
                </li>
              ))
            ) : (
              <li className="d-flex justify-content-center align-items-center no-questions">
                <div className="sd-w-80 text-center">
                  <p className="color-black font-14 font-medium mb-0">
                  Sorry! You don’t have any recent unresolved questions.
                  </p>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
  const bindFAQ = () => {
    const faqs = dashBoardData?.frequently_asked_question ?? [];
  
    return (
      <div className="col-md-12 col-lg-12">
        <div className="sd-card">
          <div className="d-flex justify-content-between">
            <h3 className="font-16 font-medium color-black mb-2">Top Asked Questions</h3>
          </div>
          {faqs.length > 0 && (
            <div className="d-flex align-items-center pt-3 pb-2">
              <div className="sd-w-80">
                <p className="color-greyv1 font-14 font-regular mb-0">
                  Questions
                </p>
              </div>
            </div>
          )}
          <div className="accordion" id="accordionExample">
            {faqs.length > 0 ? (
              faqs.map((item, index) => (
                <div key={index} className="accordion-item border-bottom border-0 rounded-0">
                  <h2 className="accordion-header my-3" id={`faq-heading${index}`}>
                    <button
                      className="custom-accordion-btn collapsed w-100 d-flex font-medium font-14 grey-v3 text-start align-items-center p-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#faq-collapse${index}`}
                      aria-expanded="true"
                      aria-controls={`faq-collapse${index}`}
                    >
                      {item?.question}
                    </button>
                  </h2>
                  <div
                    id={`faq-collapse${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`faq-heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body custom-box-shadow custom-left-border mb-3">
                      <div className="custom-line">
                        <div className="d-flex align-items-center">
                          <img
                            className="me-2"
                            src="./image/ocean-ac.svg"
                            alt="profile-image"
                          />
                          <span className="font-14 font-semibold color-black">
                            Ocean Chat Assist
                          </span>
                        </div>
                        <p className="mb-0 font-regular font-14 color-greyv1 mt-2 custom-lineheight">
                          {item?.answer}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="d-flex align-items-center">
                <div className="sd-w-80">
                  <p className="color-black font-14 font-medium mb-0 no-faqquestions">
                  Sorry! You don’t have any frequently asked question.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  
  

  const bindDurationData = () => {
    return (
      <div className="dropdown me-2">
        <button
          className="btn btn-secondary dropdown-toggle sd-select font-14"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {selectedDuration}
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          {durationData.map((option, index) => (
            <li key={index}>
              <a
                className="dropdown-item font-regular text-left"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleDurationChange(String(option));
                }}
              >
                {option}
              </a>
            </li>
          ))}
          <li>
            <a
              className="dropdown-item font-regular text-left"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleDurationChange("Custom Date");
              }}
            >
              Custom Date
            </a>
          </li>
        </ul>

        {showStartDatePicker && (
          <div ref={startDatePickerRef} className='custom-date-picker'>
            <h3 className='font-medium font-14 color-black'>Select Start Date</h3>
            <DatePicker
              selected={dateRange?.startDate}
              onChange={handleStartDateChange}
              dateFormat="dd/MM/yyyy"
              selectsStart
              startDate={dateRange?.startDate ?? undefined}
              endDate={dateRange?.endDate ?? undefined}
              inline
              minDate={minSelectableDate ?? undefined} // Setting minDate here
              maxDate={maxSelectableDate}
            />
          </div>
        )}

        {showEndDatePicker && (
          <div ref={endDatePickerRef} className='custom-date-picker'>
            <h3 className='font-medium font-14 color-black'>Select End Date</h3>
            <DatePicker
              selected={dateRange?.endDate}
              onChange={handleEndDateChange}
              dateFormat="dd/MM/yyyy"
              selectsEnd
              startDate={dateRange?.startDate ?? undefined}
              endDate={dateRange?.endDate ?? undefined}
              minDate={dateRange?.startDate ?? minSelectableDate??undefined} // Ensuring minDate starts from selected startDate or minimum selectable date
              maxDate={maxSelectableDate}
              inline

            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
    {loading &&<Loader/>}
    <div className='"font-regular body-color"'>
      <div className="d-flex">
    <div className="side-nav-container">
      {/* Side Nav - Starts here */}
      <div
        className="d-flex flex-column flex-shrink-0 p-3 theme-black vh-100 display-mobtab-none position-relative"
        id="navWid"
        style={{ width: 280 }}
      >
        <span
          className="text-center my-4"
          id="content-logo-collapse"
          style={{ height: 60 }}
        >
          <a href="#" className=" d-inline-block ">
            <img
              src="image/ocean-casino-logo-full.svg"
              alt="ocean-casino-logo"
            />
          </a>
        </span>
        <ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow mt-4">
          <li className="side-nav-item d-flex align-items-center mb-3 font-14 font-medium gap-3 active">
            <img src="image/customer-service-icon.svg" alt="cs-icon" />{" "}
            <span className="navName d-inline-block" onClick={()=>{
              window.location.reload();
            }}>Customer Service</span>
          </li>
        </ul>
        
      </div>
      <nav className="navbar navbar-expand-md navbar-light theme-black py-3 display-mobtab-block w-100">
        <div className="container-fluid">
          <a className="navbar-brand ms-2" href="#">
            <img
              src="image/ocean-casino-logo-full.svg"
              alt="brand-logo"
              width="140px"
            />
          </a>
          <button
            className="navbar-toggler custom-toggler collapsed"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sideNavOffCanvas"
            aria-expanded="false"
          >
            <span className="navbar-toggler-icon" />
          </button>
        </div>
      </nav>
      <div
        className="offcanvas offcanvas-start custom-width theme-black"
        tabIndex={-1}
        id="sideNavOffCanvas"
        style={{ zIndex: 9999 }}
      >
        <div className="offcanvas-header">
          <span
            className="text-center my-4"
            id="content-logo-collapse"
            style={{ height: 33 }}
          >
            <a href="#" className=" d-inline-block ">
              <img src="image/ocean-casino-logo-full.svg" alt="superdesk-logo" />
            </a>
          </span>
        </div>
        <div className="offcanvas-body">
          <ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow mt-4">
            
            <li className="side-nav-item d-flex align-items-center mb-3 font-14 font-medium gap-3 active">
              <img src="image/customer-service-icon.svg" alt="cs-icon" />{" "}
              <span className="navName d-inline-block" >Customer Service</span>
            </li>
            
          </ul>
          <div
            className="conten-between position-absolute end-0 start-0 bottom-0  px-3 py-2 mx-3 mb-2 border-radius-12"
            id="content-collapse"
          >
            
            
          </div>
        </div>
      </div>
    </div>
    <div className="content-container">
      {/* Page Title */}
      
      <div className="d-md-flex justify-content-between mb-4">
        <h1 className="font-32 shadow-black font-bold color-black">Customer Service</h1>
        <div className="d-flex justify-content-end">
          {/* <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle sd-select font-14"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Current Month
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a className="dropdown-item" href="#">
                  Action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </li>
            </ul>
          </div> */}
          {bindDurationData()}
          
        </div>
      </div>
      {/* 1st Section - Starts here */}
      <div className="row mb-4">
        <div className="col-md-7 col-lg-8 mb-4">
          <div className="sd-card h-100">
          <h3 className="font-16 font-medium color-black">
          Impact based on Ocean’s Chatbot Responses
                </h3>
            {/* Tab Structure here */}
            <div className="tab-content" id="pills-tabContent">
              {/* Incident here */}
              <div
                className="tab-pane fade show active"
                id="incidents"
                role="tabpanel"
              >
                <div>
                
                  <div className="text-center">
                    {/* <img
                      src="image/bar-graph.svg"
                      alt="Graph"
                      className="w-100"
                    /> */}
                    <BarChart impactData={dashBoardData?.bar_graph_data}/>
                    {/*Graph component should be placed here*/}
                    
                  </div>
                </div>
              </div>
              {/* Response Time here */}
              <div className="tab-pane fade" id="response_time" role="tabpanel">
                Add Plugin Here
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 col-lg-4 mb-4 sd-overall-rate">
          <div className=" text-center h-100 d-flex align-items-center justify-content-center flex-column ">
            <p className="font-24 font-medium color-white">
              Overall Customer Satisfaction Score
            </p>
            <p className="font-56 font-semibold color-white mb-0">{dashBoardData?.satisfactory_score?.satisfaction_score||0}</p>
          </div>
        </div>
      </div>
      {/* 2nd Section - Starts here */}
      <div className="">
        <div className="d-flex align-items-center">
          <p className="font-14 sd-dash-acc-title w-100 my-3">
            <span className="font-medium color-black">Chatbot insights</span>
          </p>
         
        </div>
        <div className="py-4 pb-0 collapse show" id="collapseLeads">
          <div className="row">
            <div className="col-sm-6 col-md-4 mb-4">
              <div className="sd-card">
                <h3 className="font-16 font-medium color-black">
                  #Satisfactory Count
                </h3>
                <p className="font-28 font-semibold mb-3 color-black">{dashBoardData?.satisfactory_score?.yes_count||0}<span className="font-20 font-medium">{'/'+(dashBoardData?.satisfactory_score?.total_count||0)}</span></p>
                
              </div>
            </div>
            <div className="col-sm-6 col-md-4 mb-4">
              <div className="sd-card">
                <h3 className="font-16 font-medium color-black">
                  Avg. Response Time
                </h3>
                <p className="font-28 font-semibold mb-3 color-black">
                  {dashBoardData?.average_response_time||0} <span className="font-20 font-medium">sec</span>
                </p>
               
              </div>
            </div>
            <div className="col-sm-6 col-md-4 mb-4">
              <div className="sd-card">
                <h3 className="font-16 font-medium color-black">
                  Avg. Engagement Time
                </h3>
                <p className="font-28 font-semibold mb-3 color-black">
                  {dashBoardData?.average_engagement_time?.value||0} <span className="font-20 font-medium">{dashBoardData?.average_engagement_time?.unit||'min'}</span>
                </p>
                
              </div>
            </div>
            {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3 flex-gap mb-4">
              <div className="sd-card sd-height-50">
                <h3 className="font-16 font-medium color-black">
                  Lead to Customer Conversion
                </h3>
                <p className="font-40 font-semibold mb-3">10%</p>
                <p className="font-12 font-regular mb-0 color-greyv1">
                  <span className="green-badge me-2">-20%</span>decrease than
                  last month
                </p>
              </div>
              <div className="sd-card sd-height-50 d-none d-md-block">
                <h3 className="font-16 font-medium color-black">CSAT Score</h3>
                <span className="d-flex justify-content-between align-items-center">
                  <span className="font-40 font-semibold color-brand">10%</span>
                  <span className="text-end font-semibold rating-icon">
                    <img
                      src="image/rating-icon.svg"
                      alt="Rating Icon"
                      width="40px"
                    />
                  </span>
                </span>
              </div>
            </div>
            <div className="col-sm-12 d-block d-md-none mb-4">
              <div className="sd-card">
                <h3 className="font-16 font-medium color-black mb-3">
                  CSAT Score
                </h3>
                <span className="d-flex justify-content-between align-items-center">
                  <span className="font-40 font-semibold color-brand">10%</span>
                  <span className="text-end font-semibold rating-icon">
                    <img
                      src="image/rating-icon.svg"
                      alt="Rating Icon"
                      width="40px"
                    />
                  </span>
                </span>
              </div>
            </div> */}
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-4">
              <div className="sd-card custom-height">
                <h3 className="font-16 font-medium color-black">
                Incident Status Analysis of monday.com
                </h3>
                <div className="text-center mt-4">
                  {/* <img src="image/pie-chart.svg" alt="pei-chart" className="" /> */}
                  <DonutChart solved={dashBoardData?.incidents?.resolved_incidents==null||dashBoardData?.incidents?.resolved_incidents==undefined?0:dashBoardData?.incidents?.resolved_incidents} unresolved={dashBoardData?.incidents?.unresolved_incidents==null||dashBoardData?.incidents?.unresolved_incidents==undefined?0:dashBoardData?.incidents?.unresolved_incidents}/>
                  {/*Pie chart component here*/}
                </div>
                {/* <div className="m-auto w-75 mt-4">
                  <span className="d-flex justify-content-between align-items-center mb-2">
                    <span className="green-legend-style font-12 font-regular color-black">
                      Resolved Incidents
                    </span>
                    <span className="text-end font-semibold">{dashBoardData.incidents.resolved_incidents}</span>
                  </span>
                  <span className="d-flex justify-content-between align-items-center">
                    <span className="blue-legend-style font-12 font-regular color-black">
                      Unresolved Incidents
                    </span>
                    <span className="text-end font-semibold">{dashBoardData.incidents.unresolved_incidents}</span>
                  </span>
                </div> */}
              </div>
            </div>
            {bindUnresolvedQuestion()}
            {bindFAQ()}
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

    </>
  );
};


// export default Dashboard;
