import React from 'react';
import { CSSProperties } from 'react';
 
const Loader: React.FC = () => {
  const loaderStyles: { [key: string]: CSSProperties } = {
    loaderWrapper: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 10001, // Ensure it's above the blur overlay
    },
    imageContainer: {
      // You can add specific styles for the image container if needed
    },
    loaderImage: {
      width: '150px',
      height: 'auto',
    },
    blurOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(255, 255, 255, 0.5)', // Semi-transparent background
      backdropFilter: 'blur(5px)', // Blur effect
      zIndex: 10000, // Ensure it's below the loader but above other content
    }
  };
 
  return (
    <>
      <div style={loaderStyles.blurOverlay} />
      <div style={loaderStyles.loaderWrapper}>
        <div style={loaderStyles.imageContainer}>
          <img
            src="/image/ocean-casino-loader.gif"
            alt="Loading...."
            style={loaderStyles.loaderImage}
          />
        </div>
      </div>
    </>
  );
};
 
export default Loader;
