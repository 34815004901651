import React, { useState, useEffect, useRef } from "react";
// import { format, parseISO } from "date-fns-tz";

import { getJwtToken, getGreeting, makeAgentRequest, sendSatisfactionRequest } from "../Service/Api";
import {
  userDetails,
  ChatMessage,
  ChatConversation,
  BotConfig,
} from "../Interface/Interface";
import BindBotResponse from "./BindBotResponse";
import LoadingIndicator from "../OtherComponents/Loader";
import { log } from "console";
import { Urls } from "../Service/Api-Constant";
interface ChatMessageData {
  role: string;
  content: string;
  timestamp: string;
  is_user_satisfied?: string;
}
/**
 * PC_CB_1.1 to PC_CB_1.4 Necessary imports and useEffect
 * PC_CB_1.47 to PC_CB_1.49 get the input from the user and store it in a state variable inputValue
 * @returns the chat messages
 */

export function Chatbot() {
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<any>("");
  const [botCss, setBotCss] = useState({ body: false });
  const [inputVal, setInputVal] = useState<any>("");
  const [showChatBot,setShowChatBot]=useState<Boolean>(false)
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const [feedback, setFeedback] = useState<{ [key: number]: string }>({});
  const [chatMessages, setChatMessages] = useState<any>({
    chatConversation: [
      {
        data: {
          content: "Hi! What question can I answer for you?",
          contentType: "txt",
        },
        inputConfig: {
          disableAttachment: 1,
          disableEmoji: 1,
          disableText: 0,
          disableVoice: 1,
          hiddenAttachment: 1,
          hiddenEmoji: 1,
          hiddenText: 0,
          hiddenVoice: 1,
        },
        meta: {
          name: "Superdesk",
          timeStamp: new Date(),
        },
        order: 1,
        role: "bot",
      },
    ],
    metaData: {
      conversationID: 0,
      entities: {},
      intent: ""
    },
  });
  const [botConfig, setBotConfig] = useState<BotConfig>({
    chatbot_name: "Bot",
    chatbot_logo: "",
    enable_file_attachment: false,
    enable_voice: false,
    enable_emoji: false,
  });

  
  function bindUUID(uuid: string) {
    const updatedState = { ...chatMessages };

    // Update the user_id value in the metaData userData object
    (updatedState.metaData?.userData ?? {}).user_id = uuid;

    // Set the updated state
    setChatMessages(updatedState);
  }

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Perform your action here
      
      if(chatMessages?.chatConversation?.length>2)
        {
        if(!hasUserProvidedFeedbackForLastBot(chatMessages,feedback)){
      const lastMessage=getLastConversations(chatMessages)
     if (lastMessage && lastMessage?.data) {
    // Find the bot message in the last conversation data
    const botMessage:ChatMessageData|undefined = lastMessage?.data.find(message => message.role === 'AI');

    if (botMessage) {
      // Add or update the `is_user_satisfied` key based on the feedback type
      botMessage.is_user_satisfied ='yes'
    }
    
  }
  sendSatisfactionRequest(lastMessage?.uuid,lastMessage?.data)
}
        }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [chatMessages]);
  function scrollToBottom() {
    
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef?.current?.scrollHeight;
      chatContainerRef.current.scrollTo({
        top: chatContainerRef?.current?.scrollHeight,
        behavior: "smooth",
      });
    }
  }
 
  const getLastBotMessage = (chatMessages: any) => {
    const botMessages = chatMessages?.chatConversation.filter((message: any) => message.role === 'bot');
    
    return botMessages.length > 0 ? botMessages[botMessages.length - 1] : null;
  };

  const hasUserProvidedFeedbackForLastBot = (chatMessages: any, feedback: { [key: number]: string }) => {
    const lastBotMessage = getLastBotMessage(chatMessages);
  
    
  
    // Check if disableEmoji is 0
    const emojiEnabled = lastBotMessage?.data?.inputConfig?.disableEmoji === 0;
  
    if (!emojiEnabled) {
      return false;
    }
  
    // If emojis are enabled, then check if the user has provided feedback
    const lastBotIndex = chatMessages.chatConversation.indexOf(lastBotMessage);
    return feedback[lastBotIndex] !== undefined;
  };
  
  const handleFeedback = (type: string, index: number) => {
    setFeedback((prevState) => ({ ...prevState, [index]: type }));
    const lastMessage=getLastConversations(chatMessages)
     if (lastMessage && lastMessage.data) {
    // Find the bot message in the last conversation data
    const botMessage:ChatMessageData|undefined = lastMessage.data.find(message => message.role === 'AI');

    if (botMessage) {
      // Add or update the `is_user_satisfied` key based on the feedback type
      botMessage.is_user_satisfied = type === 'up' ? 'yes' : 'no';
    }
    const updatedMetaData = { 
      ...chatMessages?.metaData,
      entities: {
        ...chatMessages?.metaData?.entities,
        ticket_flag: type === 'up' ? 0 : 1,
      },
    };

    // Update the chatMessages state with the new metadata
    setChatMessages({
      ...chatMessages,
      metaData: updatedMetaData,
    });
  }
  const followUpQuestion = chatMessages?.metaData?.entities?.followUpQuestion ?? null;
  if(followUpQuestion)
  {
  sendSatisfactionRequest(lastMessage?.uuid,lastMessage?.data,followUpQuestion)
  }
  else{
    sendSatisfactionRequest(lastMessage?.uuid,lastMessage?.data)
  }

  };
  const getLastConversations = (chatMessages: any) => {
    const { chatConversation, metaData } = chatMessages;
    const { entities } = metaData;
    const { uuid } = entities; // Extract uuid from entities
    const is_user_satisfied=''
    if (chatConversation.length === 0) {
      return null;
    }
  
    // Find the last user message
    const lastUserMessage = [...chatConversation].reverse().find(message => message.role === 'user');
    // Find the last bot message
    const lastBotMessage = [...chatConversation].reverse().find(message => message.role === 'bot');
  
    // Prepare the data array with last user and bot messages
    const data = [];
  
    if (lastUserMessage) {
      data.push({
        role: lastUserMessage?.role,
        content: lastUserMessage?.data?.content,
        timestamp: lastUserMessage?.meta?.timeStamp // Assuming user timestamps are in ISO format
      });
    }
  
    if (lastBotMessage) {
      data.push({
        role: 'AI',
        content: lastBotMessage?.data?.content,
        timestamp: convertBotTimezoneToUserTimezone(lastBotMessage?.meta?.timeStamp)
      });
    }
  
    return {
      lastUserMessage: lastUserMessage ? {
        content: lastUserMessage?.data?.content,
        role: lastUserMessage?.role,
        timestamp: lastUserMessage?.meta?.timeStamp // Assuming user timestamps are in ISO format        
      } : null,
      lastBotMessage: lastBotMessage ? {
        content: lastBotMessage?.data?.content,
        role: 'AI',
        timestamp: convertBotTimezoneToUserTimezone(lastBotMessage?.meta?.timeStamp)
      } : null,
      data,
      uuid // Include uuid in the returned object
    };
  };
  const getPreviousConversation = (chatMessages: any) => {
    const { chatConversation, metaData } = chatMessages;
    const { entities } = metaData;
    const { uuid } = entities; // Extract uuid from entities
  
    if (chatConversation.length === 0) {
      return null;
    }
  
    // Initialize variables to hold the messages we are interested in
    let secondLastUserMessage = null;
    let lastBotMessage = null;
    let userMessageCount = 0;
  
    // Iterate over the chat conversation messages in reverse
    for (let i = chatConversation.length - 1; i >= 0; i--) {
      const message = chatConversation[i];
  
      // Track bot messages
      if (!lastBotMessage && message.role === 'bot') {
        lastBotMessage = message;
      }
  
      // Track user messages
      if (message.role === 'user') {
        userMessageCount++;
        if (userMessageCount === 2) {
          secondLastUserMessage = message;
          break; // Once we found the second last user message, we can stop
        }
      }
    }
  
    // Prepare the data array with the found user and bot messages
    const data = [];
    if (secondLastUserMessage) {
      data.push({
        role: secondLastUserMessage?.role,
        content: secondLastUserMessage?.data?.content,
        timestamp: secondLastUserMessage?.meta?.timeStamp // Assuming user timestamps are in ISO format
      });
    }
    if (lastBotMessage) {
      data.push({
        role: 'AI',
        content: lastBotMessage?.data?.content,
        timestamp: convertBotTimezoneToUserTimezone(lastBotMessage?.meta?.timeStamp)
      });
    }
  
    return {
      lastUserMessage: secondLastUserMessage ? {
        content: secondLastUserMessage?.data?.content,
        role: secondLastUserMessage?.role,
        timestamp: secondLastUserMessage?.meta?.timeStamp // Assuming user timestamps are in ISO format
      } : null,
      lastBotMessage: lastBotMessage ? {
        content: lastBotMessage?.data?.content,
        role: 'AI',
        timestamp: convertBotTimezoneToUserTimezone(lastBotMessage?.meta?.timeStamp)
      } : null,
      data,
      uuid // Include uuid in the returned object
    };
  };
  
  const convertBotTimezoneToUserTimezone = (botTimestamp:string) => {
    const botDate = new Date(botTimestamp);
    return botDate.toISOString();
  };
  
  /**
   * PC_CB_1.5 to PC_CB_1.7 call getJwtToken to make an API request to get the token
   * PC_CB_1.16 call getGreeting functions to get the greeting message for the user
   * @returns the access token and bot configuration
   */
  const generateJwtToken = async () => {
    try {
      const response = await getJwtToken({
        bot_id: chatMessages?.metaData.userData.bot_id,
      });

      if (response.status == 200) {
        setBotConfig(response.data);
        return response.headers.access_token;
      } else {
      }
    } catch (error: any) {
      console.error("Error generating JWT:", error);
      setError(error.message);
    }
  };

  /**
   * PC_CB_1.26 to PC_CB_1.29 set the response to chatMessage state variable
   * @returns the greeting message
   */
  const generateGreetingMessage = async () => {
    try {
      const response = await getGreeting({ body: chatMessages });

      if (response.status == 200) {
        setChatMessages(JSON.parse(response?.data?.body));
      } else {
      }
    } catch (error) {
      console.error("Error generating greeting message:", error);
    }
  };
  /**
   * PC_CB_1.30 to PC_CB_1.33 duplicate the object in chatMessages and update the
   *  values and add the an object to the chatMessages
   * @param value content
   * @param type content types
   * @returns
   */
  const addUserRequest = (
    value: string,
    type: string,
    role: string
  ): boolean => {
    const lastMessage =
      chatMessages?.chatConversation[chatMessages.chatConversation.length - 1];
    const duplicatedMessage = {
      ...lastMessage,
      order: lastMessage.order + 1,
      role: role,
      meta: {
        ...lastMessage.metaData,
        timeStamp: new Date(),
      },
      inputConfig: {
        ...lastMessage.inputConfig,
        disableText: 1,
      },
      data: {
        ...lastMessage.data,
        content: value,
        contentType: "txt",
      },
    };
    if (
      type == "chcs" ||
      type == "multchcs" ||
      type == "dropdwn" ||
      type == "btn"
    ) {
      if (Array.isArray(lastMessage?.data?.content)) {
        lastMessage?.data?.content?.forEach((choice: any) => {
          choice.selected = choice.options === value;
        });
      }
    }
    chatMessages?.chatConversation.push(duplicatedMessage);
    setChatMessages({ ...chatMessages });

    return true;
  };

  /**
   * PC_CB_1.34 to PC_CB_1.37 loop through the chatMessages conversations check for the role
   * PC_CB_1.38 to PC_CB_1.39 bind the bot response by bindBotResponse component
   * PC_CB_1.40 to PC_CB_1.42 bind the user content by dynamic components
   * @returns the chat
   */
  const bindChatMessages = () => {
    try {
      if (Object.keys(chatMessages).length !== 0) {
        return chatMessages?.chatConversation?.map((val: any, index: number) => {
          let button = val?.data?.contentType;
          let type = chatMessages?.chatConversation[index + 1]?.data?.contentType;
  
          let datetimevalue = val?.meta?.timeStamp;
          
          switch (val.role) {
            case "bot":
              return (
                <div key={index} className="ai-bot-response-container">
                  <div className="ai-bot-response">
                    <div className="ai-bot-content" style={{ whiteSpace: "pre-wrap" }}>
                      <BindBotResponse
                        record={{
                          value: val.data,
                          func: handleResponseBinding,
                        }}
                      />
                      <span className="ai-bot-avatar">
                        <img
                          src={
                            botConfig?.chatbot_logo
                              ? botConfig?.chatbot_logo
                              : "image/chatbot-avatar.svg"
                          }
                          alt="chatbot avatar icon"
                        />
                      </span>
                      {val?.data?.inputConfig?.disableEmoji === 0 && (
                        !feedback[index] && (
                      <div className="react">
                        <span
                          className="emoji"
                          onClick={() => handleFeedback("up", index)}
                        >
                          &#128077;
                        </span>
                        <span
                          className="emoji"
                          onClick={() => handleFeedback("down", index)}
                        >
                          &#128078;
                        </span>
                      </div>
                    )
                  )}


  
                    {/* Feedback result */}
                    {feedback[index] && (
                      <div className="react">
                        {feedback[index] === "up" && (
                          <span className="emoji">&#128077;</span>
                        )}
                        {feedback[index] === "down" && (
                          <span className="emoji">&#128078;</span>
                        )}
                      </div>
                    )}
                    </div>
                    {type === "btn" && button === "txt" ? null : (
                      <span className={` ${
                        ((type === "btn" || type === undefined) && button === "txt") ||
                        button === "crd"
                          ? "bot-time"
                          : (type === "txt" || type === undefined) && button === "txt"
                          ? "lt"
                          : (type !== "txt" || type === "btn" || type === undefined) && button === "txt"
                          ? "lt"
                          : button === "crsl" || button === "addcart"
                          ? "bot-time"
                          : "lft"
                      }`}>
                        {convertTime(datetimevalue, "bot")}
                      </span>
                    )}
                    
                    {/* Thumbs up and thumbs down buttons */}
                    
                  </div>
                </div>
              );
            case "user":
              return (
                <div key={index} className="ai-user-response-container">
                  <div className="ai-user-response">
                    <div className="ai-user-content" style={{ whiteSpace: "pre-wrap" }}>
                      <BindBotResponse
                        record={{
                          value: val.data,
                          func: handleResponseBinding,
                        }}
                      />
                    </div>
                    <span className="user-chat-time">
                      {convertTime(datetimevalue, "user")}
                    </span>
                  </div>
                </div>
              );
          }
        });
      }
    } catch (error) {
      addUserRequest(
        "Something went wrong, please try again later.",
        "txt",
        "bot"
      );
    }
  };
  
  /**
   * PC_CB_1.43 to PC_CB_1.46 will return the formatted time
   * @param dateTime formatted time
   * @returns
   */
  const convertTime = (dateTime: any, role: string) => {
    if (dateTime) {
      const datetimeObj1 = new Date(dateTime);
      const formattedTime1 = datetimeObj1.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      });
      return formattedTime1;
    } else {
      let time = new Date();
      const datetimeObj1 = new Date(time);
      const formattedTime1 = datetimeObj1.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      });
      return formattedTime1;
    }

    // Require Moment & Moment-Timezone
  };
  /**
   * PC_CB_1.50 to PC_CB_1.52 to add the user request to the chat by calling addUserRequest()
   * @param value content
   * @param type content type
   * @returns
   */
  const executeSendActions = async (
    value: string,
    type: string,
    role: string
  ) => {
    setLoader(true);
    if (addUserRequest(value, type, role)) {
      if (await executeAgentRequest()) {
        return true;
      }
    } else {
      addUserRequest(
        "Something went wrong Try again after sometimes",
        "txt",
        "bot"
      );
    }
    return false;
  };
  /**
   * PC_CB_1.53 to to PC_CB_1.54 call makeAgentRequest to get the response for user request
   * PC_CB_1.63 to PC_CB_1.64 set the response to the chatMessages variable
   * @returns
   */
  const executeAgentRequest = async (): Promise<boolean> => {
    try {
      if(chatMessages.chatConversation.length!=2)
      {
      if(!hasUserProvidedFeedbackForLastBot(chatMessages,feedback)){
        const lastMessage=getPreviousConversation(chatMessages)
      
        const updatedChatMessages = { ...chatMessages };
  const chatConversation = [...updatedChatMessages.chatConversation];

  // Iterate through chatConversation to find the last bot response
  for (let i = chatConversation.length - 1; i >= 0; i--) {
    if (chatConversation[i].role === "bot") {
      // Found the last bot response, update disableEmoji to 1
      if (chatConversation[i]?.data?.inputConfig) {
        chatConversation[i].data.inputConfig.disableEmoji = 1;
      }
      break; // Exit the loop after finding the last bot response
    }
  }

  // Update the state with the modified chatMessages
  setChatMessages({
    ...updatedChatMessages,
    chatConversation: chatConversation,
  });
     if (lastMessage && lastMessage.data) {
    // Find the bot message in the last conversation data
    const botMessage:ChatMessageData|undefined = lastMessage.data.find(message => message.role === 'AI');

    if (botMessage) {
      // Add or update the `is_user_satisfied` key based on the feedback type
      botMessage.is_user_satisfied = 'yes'
    }
    sendSatisfactionRequest(lastMessage?.uuid,lastMessage?.data)
      }

      }
      
      }
    
      const response = await makeAgentRequest({ body: chatMessages });


      if (
        response.status &&
        response.status == 200 &&
        response.data?.body != "null"
      ) {
        let objs=JSON.parse(response.data?.body)
        objs?.chatConversation?.forEach((obj:any, index:number) => {
          if (obj.meta.timeStamp == null) {
            objs.chatConversation[index].meta.timeStamp=new Date()
          }
        });
        setChatMessages(objs);
        setLoader(false);
        return true;
      } else {
        setLoader(false);
        addUserRequest("Something went wrong try again", "txt", "bot");
        return false;
      }
    } catch (error) {
      console.error("Error executing agent request:", error);
      return false;
    }
  };
  /**
   * PC_CB_1.65 to PC_CB_1.69 get the user request from other components and bind it to the chat
   * @param value
   * @param type
   */
  const handleResponseBinding = (value: any, type: string) => {
    if (value != "") {
      if (type == "multchc") {
        let val = value.join(",");
        executeSendActions(val, type, "user");
      } else {
        executeSendActions(value, type, "user");
      }
    }
  };
  return (
    <>
    <span onClick={()=>{
      setShowChatBot(true)
    }} className="chatbot-icon-container"><img src="image/chatbot-logo.svg" className="chatbot-img"/></span>
      {showChatBot && (<div className="ai-container" >
        <div className="ai-header-bg">
        <div className="ai-header">
        <h3 className="heading">Ocean's AI Assist</h3>
        <span onClick={()=>{
          setShowChatBot(false)
        }} className="close-btn"><img src="image/bot-close-icon.svg" alt="minus-icon"/></span>
          </div>
        </div>
      {/* over */}
        <div className="ai-body-bg">
        <div className="ai-body">
          <div className="bot-content-container" ref={chatContainerRef}>
            <></>
            {Object.keys(chatMessages).length ? bindChatMessages() : null}
            {loader ? <LoadingIndicator /> : null}
            {error ? <span>{error}</span> : null}
          </div>
          {/* <div className="ai-downarrow">
            <i className="fa-solid fa-arrow-down"></i>
          </div> */}
          </div>
        </div>
        <div className="ai-footer">
          {/* <div className="ai-voice-input">
            <img
              hidden={
                Array.isArray(chatMessages?.chatConversation)
                  ? chatMessages?.chatConversation[
                      chatMessages?.chatConversation?.length - 1
                    ]?.inputConfig?.hiddenVoice == 1
                    ? true
                    : chatMessages?.chatConversation[
                        chatMessages?.chatConversation?.length - 1
                      ]?.inputConfig?.hiddenVoice == 0
                    ? false
                    : botConfig?.enable_voice == true
                    ? true
                    : botConfig?.enable_voice == false
                    ? true
                    : true
                  : false
              }
              style={{
                cursor: Array.isArray(chatMessages?.chatConversation)
                  ? chatMessages?.chatConversation[
                      chatMessages?.chatConversation?.length - 1
                    ]?.inputConfig?.disableVoice == 1
                    ? "not-allowed"
                    : "pointer"
                  : "not-allowed",
                opacity: Array.isArray(chatMessages?.chatConversation)
                  ? chatMessages?.chatConversation[
                      chatMessages?.chatConversation?.length - 1
                    ]?.inputConfig?.disableVoice == 1
                    ? 0.5
                    : 1
                  : 1,
              }}
              className="ai-audio-icon"
              src="image/voice-input-icon.svg"
              alt="Audio icon"
            />
          </div> */}
          <div className="ai-txt-input">
            <input
              value={inputVal}
              disabled={
                Array.isArray(chatMessages?.chatConversation)
                  ? chatMessages?.chatConversation[
                      chatMessages?.chatConversation?.length - 1
                    ]?.inputConfig?.disableText == 1
                    ? true
                    : chatMessages?.chatConversation[
                        chatMessages?.chatConversation?.length - 1
                      ]?.inputConfig?.disableText == 0
                    ? false
                    : true
                  : true
              }
              hidden={
                Array.isArray(chatMessages?.chatConversation)
                  ? chatMessages?.chatConversation[
                      chatMessages?.chatConversation?.length - 1
                    ]?.inputConfig?.hiddenText == 1
                    ? true
                    : false
                  : false
              }
              onKeyUp={(e) => {
                if (e.key == "Enter") {
                  if (inputVal.trim() != "") {
                    executeSendActions(inputVal, "txt", "user");
                    setInputVal("");
                  }
                }
              }}
              onChange={(e) => {
                
                setInputVal(e.target.value);
              }}
              className="ai-input-control"
              type="text"
              placeholder="Type your message"
            />
            <button type="button" className="send-btn" onClick={(e) => {
                if (inputVal.trim() != "") {
                  executeSendActions(inputVal, "txt", "user");
                  setInputVal("");
                }
              }}>
            <img
              src="image/send-icon.svg"
              alt="Send icon"
              style={{
                cursor: Array.isArray(chatMessages?.chatConversation)
                  ? chatMessages?.chatConversation[
                      chatMessages?.chatConversation?.length - 1
                    ]?.inputConfig?.disableText == 1
                    ? "not-allowed"
                    : chatMessages?.chatConversation[
                        chatMessages?.chatConversation?.length - 1
                      ]?.inputConfig?.disableText == 0
                    ? "pointer"
                    : "not-allowed"
                  : "not-allowed",
                opacity: Array.isArray(chatMessages?.chatConversation)
                  ? chatMessages?.chatConversation[
                      chatMessages?.chatConversation?.length - 1
                    ]?.inputConfig?.disableText == 1
                    ? 0.5
                    : chatMessages?.chatConversation[
                        chatMessages?.chatConversation?.length - 1
                      ]?.inputConfig?.disableText == 0
                    ? 1
                    : 0.5
                  : 0.5,
              }}/>
              
            
          </button>
          </div>
          
          
        </div>
      </div>)}
    </>
  );
}
